@font-face {
  font-family: "AppFontRegular";
  src: url(../public/MPLUSRounded1c-Regular.ttf);
}

@font-face {
  font-family: "AppFontMedium";
  src: url(../public/MPLUSRounded1c-Medium.ttf);
}

@font-face {
  font-family: "AppFontBold";
  src: url(../public/MPLUSRounded1c-Bold.ttf);
}


/* html, body, body > div, .app {
  height: 100%;
  width: 100%;
} */

* {
  font-family: 'AppFontRegular';
}

.App {
  background-color: white;
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.App-header {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: black;
}

.Privacy-header {
  min-height: 90vh;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: center; */
  font-size: calc(5px + 2vmin);
  color: black;
  max-width: 60vh;
  margin: auto;
  text-align: left;
  padding-top: 3em;
  padding-bottom: 3em;
}

.App-link {
  color: #61dafb;
}

.App-store-button {
  height: 6vmin;
}


.App-footer {
  background-color: white;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: black;

  display: flex;
  flex-direction: row;
  gap: 20px;
}

a {
  text-decoration: none;
  color: black;
}

.App-link {
  color: #61dafb;
}

h1 {
  font-size: 8vmin;
  /* font-weight: bold; */

  margin-top: 0em;
  margin-bottom: 0em;
  margin-left: 0;
  margin-right: 0;
}

h2 {
  font-size: 1.5em;

  margin-top: 0.5em;
  margin-bottom: 0em;
  margin-left: 0;
  margin-right: 0;

  /* font-weight: bold; */
}

h3 {
  font-size: 0.8em;

  margin-top: 0em;
  margin-bottom: 0em;
  margin-left: 0;
  margin-right: 0;

}

h4 {
  margin-top: 0.5em;
  font-weight:normal;
}

p {
  font-family:'AppFontRegular';
  color: black;
  opacity: 0.7;
  font-size: large;
  margin-top: 0.5em;
}

h6 {
  margin-top: 0.5em;
  padding-top: 0em;
  opacity: 0.7;
  font-variation-settings: "wght" 200;
}